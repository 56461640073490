import TrackerProvider from '@fingo/lib/components/tracker/TrackerProvider';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Layout from './components/layout';
import HotjarProvider from './views/App/HotjarProvider';

const App = () => (
  <Router>
    {/* Estos providers no se pueden dejar atrás porque usan ruteo */}
    <HotjarProvider>
      <TrackerProvider
        frontendName="user-frontend"
        googleAnalyticsTag={process.env.REACT_APP_GOOGLE_ANALYTICS_TAG}
      >
        <Layout>
          <AppRoutes />
        </Layout>
      </TrackerProvider>
    </HotjarProvider>
  </Router>
);

export default App;
