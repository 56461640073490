import LazyImage from '@fingo/lib/components/images/LazyImage';
import FingoNavbar from '@fingo/lib/components/layout/FingoNavbar';
import LoggedOutMobileNavItems from '@fingo/lib/components/layout/navbar/LoggedOutMobileNavItems';
import LogoNavItem from '@fingo/lib/components/layout/navbar/LogoNavItem';
import useGetUser from '@fingo/lib/hooks/useGetUser';
import useIsLogged from '@fingo/lib/hooks/useIsLogged';
import { lazy } from '@loadable/component';
import Stack from '@mui/material/Stack';
import React, { Suspense } from 'react';

const LoggedInMobileNavItems = lazy(() => import('./LoggedInMobileNavItems'));

const MobileNavbar = () => {
  const isLogged = useIsLogged();
  const user = useGetUser();

  return (
    <>
      <FingoNavbar>
        <Stack
          direction="row"
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <LogoNavItem
            image={(
              <LazyImage
                imagePath="images/logos/fingo-logo.webp"
                alt="fingo logo"
                width={90}
                height={35}
                format="avif"
              />
            )}
            isLogged={isLogged}
            id="nav-logo"
          />
          {!isLogged && <LoggedOutMobileNavItems />}
          {isLogged && user && (
            <Suspense fallback={<Stack width={400} />}>
              <LoggedInMobileNavItems />
            </Suspense>
          )}
        </Stack>
      </FingoNavbar>
    </>
  );
};

export default MobileNavbar;
