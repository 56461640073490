import theme from '@fingo/lib/theme';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import StyledEngineProvider from '@mui/styled-engine/StyledEngineProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import PropTypes from 'prop-types';
import React from 'react';

const MuiProvider = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {children}
      </LocalizationProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

MuiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MuiProvider;
