import LazyImage from '@fingo/lib/components/images/LazyImage';
import FingoNavbar from '@fingo/lib/components/layout/FingoNavbar';
import CountryNavItem from '@fingo/lib/components/layout/navbar/CountryNavItem';
import CurrencyNavItem from '@fingo/lib/components/layout/navbar/CurrencyNavItem';
import LoggedOutNavItems from '@fingo/lib/components/layout/navbar/LoggedOutNavItems';
import LogoNavItem from '@fingo/lib/components/layout/navbar/LogoNavItem';
import useGetUser from '@fingo/lib/hooks/useGetUser';
import useIsLogged from '@fingo/lib/hooks/useIsLogged';
import { lazy } from '@loadable/component';
import Stack from '@mui/material/Stack';
import React, { Suspense } from 'react';

const LoggedInDesktopNavItems = lazy(() => import('./LoggedInDesktopNavItems'));

const DesktopNavbar = () => {
  const isLogged = useIsLogged();
  const user = useGetUser();
  return (
    <>
      <FingoNavbar>
        <LogoNavItem
          image={(
            <LazyImage
              imagePath="images/logos/fingo-logo.webp"
              alt="fingo logo"
              width={120}
              height={50}
              format="avif"
            />
          )}
          isLogged={isLogged}
          id="nav-logo"
        />
        {isLogged && user && (
          <Suspense fallback={<Stack width={400} />}>
            <LoggedInDesktopNavItems />
          </Suspense>
        )}
        {!isLogged && <LoggedOutNavItems title="Comencemos" />}
        <Stack direction="row" spacing={3}>
          <CountryNavItem />
          {isLogged && (
            <Suspense fallback={<Stack width={75} height={40} />}>
              <CurrencyNavItem />
            </Suspense>
          )}
        </Stack>
      </FingoNavbar>
    </>
  );
};

export default DesktopNavbar;
