import { lazy } from '@loadable/component';

const Game = lazy(() => import('./game/index'));
const App = lazy(() => import('./views/App/App'));
const AutomaticCession = lazy(() => import('./views/AutomaticCession'));
const ChangeAssignmentCessionType = lazy(() => import('./views/ChangeAssignmentCessionType'));
const Landing = lazy(() => import('./views/Landing'));
const PasswordRecovery = lazy(() => import('./views/PasswordRecovery'));
const PreOfferEvaluation = lazy(() => import('./views/PreOfferEvaluation'));
const QuickRegistration = lazy(() => import('./views/QuickRegistration'));
const TermsAndConditions = lazy(() => import('./views/TermsAndConditions'));
const Login = lazy(() => import('./views/Login'));
const OnboardingAuth = lazy(() => import('./views/App/supplierAutomate/onboarding/SupplierOnboardingAuth'));
const EmailVerification = lazy(() => import('./views/EmailVerification'));
const OTPLogin = lazy(() => import('./views/OTPLogin'));
const ExternalLoginLanding = lazy(() => import('./features/landings/views/ExternalLoginLanding'));
const LokalCredentialsFlipCard = lazy(() => import('./components/pilots/LokalCredentialsFlipCard'));
const LandingRegisterForm = lazy(() => import('./components/pilots/LandingRegisterForm'));
const SimulatorRegister = lazy(() => import('./components/pilots/SimulatorRegister'));
const KobrosLandingRegisterForm = lazy(() => import('./components/pilots/KobrosLandingRegisterForm'));
const LandingNPS = lazy(() => import('./components/pilots/LandingNPS'));
const EvaluateInvoices = lazy(() => import('./components/pilots/EvaluateInvoices'));
const LandingRegisterWhatsappEvaluation = lazy(() => import('./components/pilots/LandingRegisterWhatsappEvaluation'));
const CollectionRegister = lazy(() => import('./features/collection/pages/CollectionRegister'));
const PartnerLoginLanding = lazy(() => import('./features/landings/views/PartnerLoginLanding'));
const PromoOperationLanding = lazy(() => import('./features/landings/views/PromoOperationLanding'));
const UserRegister = lazy(() => import('./features/landings/views/UserRegister'));
const UserLogin = lazy(() => import('./features/landings/views/UserLogin'));
const SimulatorRegisterVideoLanding = lazy(() => import('./features/landings/views/SimulatorRegisterVideoLanding'));

const appRoutes = [
  { path: '/app', component: App, private: true },
  { path: '/teuber', component: Game, private: true },
  { path: '/register', component: Login },
  { path: '/quick-registration', component: QuickRegistration },
  { path: '/login', component: Login },
  { path: '/provider-login', component: OnboardingAuth },
  { path: '/preoffer-evaluation/:preofferHash', component: PreOfferEvaluation },
  {
    path: '/change-assignment-cession-type/:invoiceHash',
    component: ChangeAssignmentCessionType,
  },
  {
    path: '/automatic-cession-config/:automaticCessionConfigHash',
    component: AutomaticCession,
  },
  { path: '/external-login', component: ExternalLoginLanding },
  { path: '/', component: Landing },
  { path: '/terms', component: TermsAndConditions },
  { path: '/password-recovery', component: PasswordRecovery },
  {
    path: '/email-verification/:encodedEmail/:token',
    component: EmailVerification,
  },
  { path: '/otp-verification/:clientId', component: OTPLogin },
  {
    path: '/landing-register',
    component: LandingRegisterForm,
  },
  {
    path: '/landing-register-whatsapp',
    component: LandingRegisterWhatsappEvaluation,
  },
  {
    path: '/lokal-providers',
    component: LokalCredentialsFlipCard,
  },
  {
    path: '/simulator-register',
    component: SimulatorRegister,
  },
  {
    path: '/simulator-register-video',
    component: SimulatorRegisterVideoLanding,
  },
  {
    path: '/simulator-register-campaign',
    component: SimulatorRegister,
  },
  {
    path: '/kobros-register',
    component: CollectionRegister,
  },
  {
    path: '/collection-register',
    component: KobrosLandingRegisterForm,
  },
  {
    path: '/evaluate',
    component: LandingNPS,
  },
  {
    path: '/evaluate-invoices',
    component: EvaluateInvoices,
  },
  {
    path: '/promo-operation',
    component: PromoOperationLanding,
  },
  {
    path: '/partner-login',
    component: PartnerLoginLanding,
  },
  {
    path: '/user-register',
    component: UserRegister,
  },
  {
    path: '/user-login',
    component: UserLogin,
  },
];

export default appRoutes;
