import React from 'react';
import Hidden from '@mui/material/Hidden';
import { useLocation } from 'react-router-dom';
import DesktopNavbar from './navbar/DesktopNavbar';
import MobileNavbar from './navbar/MobileNavbar';

const AppNavbar = () => {
  const location = useLocation();

  const noNavbarRoutes = ['/user-register', '/user-login', '/simulator-register-video']; // to delete after QA of login form
  if (noNavbarRoutes.includes(location?.pathname)) return null;
  return (
    <>
      <Hidden mdDown>
        <DesktopNavbar />
      </Hidden>
      <Hidden lgUp>
        <MobileNavbar />
      </Hidden>
    </>
  );
};

export default AppNavbar;
